import * as React from 'react'
import '../assets/css/index.css'
import Navigation from '../components/Navigation'; 
import Footer from '../components/Footer'
import { Container, Modal, Col, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
// markup
const IndexPage = () => {
  return (
    <main>
      <Navigation/>
      <GatsbySeo
        title='The Venue - Pinches Cue Club & The Venue - Snooker, Pool and Darts | Norwich Snooker and Pool Club | Private Function Room'
        description='Looking for somewhere to host an event in Norwich? We have a large function room with a private bar and huge dance floor that can be removed if needed, contact us to find out more'
        />
      <div className='black-section'>
      <Container>
            <Row>
            <h2 className='pt-3 pb-3'>Function Room Information</h2>
              <Col lg={12} md={12} sm={12} xs={12} className="black-section ">
                <p>Pinches Cue Club shares the building with a large function and private venue room that is available for private hire events in Norwich, with a fully stocked bar and large dance floor.</p>
                <p>To find out more information please call the club and ask that your interested in booking the function  room.</p>
                <h2 className='pb-3'>Address</h2>
                <address>
                85 Barker Street,
                <br />
                Norwich,
                <br />
                Norfolk.
                <br />
                NR2 4TN.
              </address>
                <a href="tel:01603 622427">01603 622427</a>
                <p>email address</p>
               </Col> 
               </Row>
               <Row>
               <Col lg={6} md={12} sm={12} xs={12} className="black-section">
                    <StaticImage src="../assets/img/function1.jpg"
                        alt="A hero image"
                        placeholder="blurred"
                        layout="constrained"
                        className='' />
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12} className="black-section">

                  <StaticImage src="../assets/img/function2.jpg"
                        alt="A hero image"
                        placeholder="blurred"
                        layout="constrained"
                        className='' />
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12} className="black-section">
                  <StaticImage src="../assets/img/function3.jpg"
                        alt="A hero image"
                        placeholder="blurred"
                        layout="constrained"
                        className='' />
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12} className="black-section">
                  <StaticImage src="../assets/img/function4.jpg"
                        alt="A hero image"
                        placeholder="blurred"
                        layout="constrained"
                        className='' />
                  </Col>
                  </Row>
      </Container>
      <Footer/>
      </div>
    </main>
  )
}

export default IndexPage
